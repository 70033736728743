import {ReplaySubject} from "rxjs";


export class ResetableReplaySubject<T> extends ReplaySubject<T> {

    /*
    * clears the internal buffer, so all subscribers stay subscribed, but they do not receive any message
    * until another next() is called
    * */
    clearBuffer():void{
        const that:any=this;
        that._events=[];
    }
}

