import {Component, ViewEncapsulation, EventEmitter, Output, Injectable} from '@angular/core';
import {BlogCategoryItemSelector, Translatable} from '../../interfaces/general';
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {BlogCategoryNodeSelector} from "./common";
import {FormBuilder, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";


@Component({
    selector: 'cmp-cat-tree',
    templateUrl: '../../tpl/blog-category-tree.html',
    encapsulation: ViewEncapsulation.None
})

@Injectable()
export class BlogCategoryTreeComponent extends Translatable {
    public selectedCategories: BlogCategoryItemSelector[] = [];
    defaultButtonText: string = "Vyberte...";
    buttonText: string;
    showTree: boolean = false;
    private ngUnsubscribe: Subject<any> = new Subject<any>();
    categories: BlogCategoryItemSelector[] = [];
    categoryTree: BlogCategoryNodeSelector;
    blogCategoryTreeForm: FormGroup = new FormGroup({});
    menuImagePathPrefix: string;
    @Output() categorySelectionChanged: EventEmitter<any> = new EventEmitter<any>();


    constructor(public dataSvc: DataService,
                public seSvc: SettingsService,
                private http: HttpClient,
                private fb: FormBuilder) {
        super(dataSvc, seSvc);
        this.setText();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getCategories();
    }

    createForm(): void {
        if (!this.categoryTree) {
            this.categoryTree.id = 0,
                this.categoryTree.name = '',
                this.categoryTree.parentId = 0,
                this.categoryTree.order = 0,
                this.categoryTree.children = []
        }

        this.blogCategoryTreeForm = this.fb.group({
            catId: [this.categoryTree.id],
            name: [this.categoryTree.name],
            parentId: [this.categoryTree.parentId],
            order: [this.categoryTree.order],
            children: [
                this.categoryTree.children.forEach(ch => [{
                    catId: ch.id,
                    name: ch.name,
                    parentId: ch.parentId,
                    order: ch.order,
                    children: ch.children
                }])
            ]
        });

    }

    public getCategories(): void {
        this.http.get<BlogCategoryItemSelector[]>(`api/blog/getCategoriesTree`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.categories = res;
            });
    }


    public getSelection(): number[] {
        return this.selectedCategories.map(c => c.id);
    }


    toggleSubMenu(elSub: any): void {
        if(this.isWrapped(elSub)){
            this.getElementByClass(elSub).classList.remove('blog-submenu--active');
        } else {
            this.getElementByClass(elSub).classList.add('blog-submenu--active');
        }
    }

    isWrapped(elSub: any): boolean {
        let el = this.getElementByClass(elSub);
        if (el)
            return el.classList.contains('blog-submenu--active');
        else
            return false;
    }

    throwEventCategorySelectionChanged(id: number, checked: boolean): void {
        let c = this.getParentCategory(this.categories, id);

        this.categorySelectionChanged.emit({id: id, add: checked, text: c.displayName});
    }

    setSelection(catId: number, add: boolean) {
        if (add) {
            if (!this.selectedCategories.some(c => c.id == catId))
                this.selectedCategories = [...this.selectedCategories, this.getParentCategory(this.categories, catId)];
        } else {
            if (this.selectedCategories.some(c => c.id == catId))
                this.selectedCategories.splice(this.selectedCategories.findIndex(c => c.id == catId), 1);
        }
        this.setText();
    }

    selectionChanged(evt: any): void {
        let id = evt.target.id.toString().replace('catId_', '');
        //this.throwEventCategorySelectionChanged(id, evt.target.checked);
        this.setSelection(id, evt.target.checked);
        let parent = this.getParentCategory(this.categories, id);
        this.setChildren(parent, evt.target.checked);
        this.categorySelectionChanged.next();
    }

    setChildren(parent: BlogCategoryItemSelector, checked: boolean): void {
        for (let s of parent.subcategories) {
            if (this.getElementById('catId_' + s.id)) {
                this.getElementById('catId_' + s.id).checked = checked;
                //this.throwEventCategorySelectionChanged(s.id, checked);
                this.setSelection(s.id, checked);
                this.setChildren(s, checked);
            }
        }
        let item = this.getElementByClass('js_blog_sub_' + parent.id);
        if (checked && item) item.classList.add('blog-submenu--active');

    }

    getParentCategory(level: BlogCategoryItemSelector[], parentId: number): BlogCategoryItemSelector {
        let parent = level.find(c => c.id == parentId);
        if (!parent) {
            for (let i of level) {
                parent = this.getParentCategory(i.subcategories, parentId);
                if (parent) {
                    return parent;
                }
            }
        }

        return parent;
    }

    setText() {
        if (this.selectedCategories != null && this.selectedCategories.length == 0)
            this.buttonText = this.defaultButtonText;
        else {
            this.buttonText = this.selectedCategories.map(c => c.displayName).join(', ');

        }

    }

    buttonClick() {
        this.showTree = !this.showTree;
    }

    showOrHide(): string {
        if (this.showTree) return '';

        return 'hidden';
    }

    getElementById(id: string): any {
        return window.document.getElementById(id);
    }

    getElementByClass(className: string): any {
        return window.document.getElementsByClassName(className).item(0);
    }
}
