/**
 * @description
 * Basic cookie manipulation since Angular 2 doesn't have it implemented
 */
// todo: write UT for this module
/**
 * @description
 * Set up the cookie
 * @param cname - cookie name
 * @param cvalue - cookie value
 * @param exhours - number of hours until expiration
 */
export function setCookie(cname: string, cvalue: string, exhours?: number): void {
    let expires: string = '';
    if (exhours) {
        let d = new Date();
        d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
        expires = "expires=" + d.toUTCString();
    }
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * @description
 * Get the value of the cookie by given name
 * @param cname - name of the cookie
 * @returns {string} - tha value of the cookie
 */
export function getCookie(cname: string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**
 * @description
 * Deletes the cookie given by its name
 * @param cname - cookie name
 */
export function deleteCookie(cname: string): void {
    setCookie(cname, '')
}

export function saveToSession(key: string, obj: any): void {
    let s = JSON.stringify(obj);
    sessionStorage.setItem(key, s)
}

export function loadFromSession(key: string): any {
    let s = sessionStorage.getItem(key);
    if (!s) return undefined;
    return JSON.parse(s)
}

export function deleteFromSession(key: string): void {
    sessionStorage.removeItem(key)
}

export function saveToLocalStorage(key: string, obj: any): void {
    let s = JSON.stringify(obj);
    localStorage.setItem(key, s)
}

export function loadFromLocalStorage(key: string): any {
    let s = localStorage.getItem(key);
    if (!s) return undefined;
    return JSON.parse(s)
}

export function removeFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
}

export function removeFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
}