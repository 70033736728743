import {OnDestroy, OnInit} from "@angular/core";
import {DataService} from "../services/data.service";
import {SettingsService} from "../services/settings.service";
import {ICurrency} from "../modules/index/common";
import {CredentialStorage} from "../services/credential-storage.service";
import {Subject} from "rxjs";

/**
 * @description
 * A class for storing authorization data in localStorage
 * Can be instantiated via:
 * - new ai()
 * - ai.fromString()
 * */
export interface AuthInfo {
    userName: string;
    loggedIn: boolean;
    validTo: string;
    developerMode: boolean;
    jwTokenBody: string;
}

export interface IKeyValue {
    key: string;
    value: string;
}

export interface RouteTranslation extends IKeyValue {
    developerOnly?: boolean;
}

export interface SettingItem {
    id: number;
    name: string;
    value: string;
    description: string;
    cultureId: number;
}

/**
 * @description
 * To have some structure of the emitted object to count with
 * Possible values for "type" are:
 * 'info'
 * 'error'
 */
export interface IEmitterMessage {
    type: string;
    message: string;
    status?: number;
    data?: any;
}

/**
 * @description
 * Defines culture properties
 */
export interface ICulture {
    code: string;
    uiCode: string;
    name: string;
    cultureId: number;
    currencyCode: string;
}

export interface ICountry {
    id: number;
    sysName: string;
    displayName: NumberIndexedString;
    phonePreset?: number;
}

export interface IArticleRoot {
    rootName: string;
}

export interface IValidationPattern {
    name: string;
    pattern: any;
    cultureId: number;
    isDefault?: boolean
}

/**
 * @description
 * Structure of the application settings which are stored in app/services/settings.service.ts.
 * This enables usage of settings via dot convention both in ts and html files.
 * If you add a setting to the json file, please add it to this interface as well.
 */
export interface ISettings {
    availDigitInfo: string;
    dateFormat: string;
    loginCookieExpirationHours: number;
    cultures: Array<ICulture>;
    currencies: Array<ICurrency>;
    defaultCultureId: number;
    defaultCurrencyId: number;
    articleRoots: IArticleRoot[];
    validationPatterns: IValidationPattern[];
    imageServerPathPrefix: string;
    countries: ICountry[];
    newsletterBannerFotoCache: string;
    newsletterProductFotoCache: string;
    primeng: IPrimeNG;
}

/**
 * @description
 * Defines an interface which should be used by every Component which needs translations
 */
export interface ITranslatable extends OnInit, OnDestroy {
    sen: Object;
}

/**
 * Base class for all components which use translations
 * translationPrefix is used to distinguish translations for particular routes (not to take them all from db)
 */
export abstract class Translatable implements ITranslatable {

    sen: any;
    private _userLoggedIn: boolean;
    unsubscribe: Subject<any> = new Subject<any>();

    protected constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        this.sen = this.seSvc.sen;
        /**
         * @description
         * we don't need to unsubscribe since we're reloading
         * thus killing all the Angular application
         */
        seSvc.cultureChanged
            .subscribe(() => location.reload());
    }

    get userLoggedIn(): boolean {
        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this._userLoggedIn = ai.loggedIn;
        }
        return this._userLoggedIn
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    GetCultureNameById(cultureId: number): string {
        const culture = this.seSvc.settings.cultures.find(x => x.cultureId === cultureId);
        return culture.name;
    }

}

export interface ILoginResult {
    success: boolean;
    cultureId: number;
    currencyId: number;
    developerMode: boolean;
    adminUserRights: string;
    jwTokenBody: string;
    tokenExpirationMinutes: number;
}

export interface PagedResponse<T> {
    total: number;
    data: T;
}

export interface IRight {
    rightToken: string;
    right: number;
}

export interface IAdminUserRights {
    userId: number;
    userName: string;
    password?: string;
    developer: boolean;
    lastLogon?: any;
    lastUpdate?: any;
    rights: IRight[];
}

export interface IPrimeNG {
    locales: any;
}

/**
 * @description
 * used to interchange info between settings service and auth.interceptor
 * for setting HttpHeaders
 */
export interface HttpRegionalSettings {
    cultureId: number;
    currencyId: number;
    comAllowed: boolean;
}

export interface DataRequest {
    url: string;
    method: string;
    data?: any;
}

export interface AdminMenuItem {
    mainRoute: string;
    titleKey: string;
    developerOnly: boolean;
    group: string;
    iconClass: string;
    globalOrder: number;
    localOrder: number;
}

export interface AdminMenuGroup {
    groupName: string;
    icon: string;
    subItems: AdminMenuItem[];
    globalOrder: number;
}

export interface NumberIndexedObject<T> {
    [key: number]: T;
}

export interface NumberIndexedString {
    [key: number]: string;
}

export interface StringIndexedObject<T> {
    [key: string]: T;
}

export const SNACKBAR_OK_MESSAGE = 'Data byla v pořádku uložena';
export const SNACKBAR_DELETE_OK_MESSAGE = 'Data byla v pořádku smazána';
export const SNACKBAR_DELETE_ERROR_MESSAGE = 'Při mazání dat došlo k chybě.';
export const SNACKBAR_ERROR_MESSAGE = 'Při ukládání dat došlo k chybě';
export const SNACKBAR_VALIDATION_ERROR = 'Formulář obsahuje chyby.';
export const SNACKBAR_DURATION = 3000;
export const SNACKBAR_RE_PROD_MESSAGE = 'Výběr již tento produkt obsahuje';
export const SNACKBAR_FILE_BAD_FORMAT_MESSAGE = 'Soubor má špatný formát';
export const SNACKBAR_IMG_BAD_SIZE_MESSAGE = 'Obrázek má špatnou velikost';

export interface GeneralRequest {
    anyString: string;
    anyNumber: number;
}

export interface BlogCategoryItemSelector {
    id: number;
    selected: boolean;
    parentId: number;
    level: number;
    displayName: string;
    subcategories: BlogCategoryItemSelector[];
}