import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
    CookiesConsentCookieSelector,
    CookiesConsentGroupSelector,
    CookiesConsentScriptSelector,
    CookiesConsentVendorSelector
} from "./common";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import {SettingsService} from "../../services/settings.service";
import {ResetableReplaySubject} from "./resettable-replay-subject";

@Injectable()
export class CookiesConsentService {

    private allGroups: ResetableReplaySubject<Array<CookiesConsentGroupSelector>> = new ResetableReplaySubject<Array<CookiesConsentGroupSelector>>(1);
    private allGroupsCalled: boolean = false;

    private allCookies: ResetableReplaySubject<Array<CookiesConsentCookieSelector>> = new ResetableReplaySubject<Array<CookiesConsentCookieSelector>>(1);
    private allCookiesCalled: boolean = false;

    private scripts: ResetableReplaySubject<Array<CookiesConsentScriptSelector>> = new ResetableReplaySubject<Array<CookiesConsentScriptSelector>>(1);
    private scriptsCalled: boolean = false;

    private vendors: ResetableReplaySubject<Array<CookiesConsentVendorSelector>> = new ResetableReplaySubject<Array<CookiesConsentVendorSelector>>(1);
    private vendorsCalled: boolean = false;



    constructor(private seSvc: SettingsService, private http: HttpClient) {
    }

    getAllGroups(forceRedownload: boolean): Observable<Array<CookiesConsentGroupSelector>> {
        if (forceRedownload || !this.allGroupsCalled) {
            this.allGroupsCalled = true;
            this.allGroups.clearBuffer();
            this.http.get<Array<CookiesConsentGroupSelector>>('api/cookies-consent/get-all-groups')
                .pipe(take(1))
                .subscribe((res) => {
                    this.allGroups.next(res);
                });
        }

        return this.allGroups;
    }

    getAllCookies(forceRedownload: boolean): Observable<Array<CookiesConsentCookieSelector>> {

        if (forceRedownload || !this.allCookiesCalled) {
            this.allCookiesCalled = true;
            this.allCookies.clearBuffer();
            this.http.get<Array<CookiesConsentCookieSelector>>('api/cookies-consent/get-all-cookies')
                .pipe(take(1))
                .subscribe((res) => {
                    this.allCookies.next(res);
                });
        }

        return this.allCookies;
    }

    getCookies(groupId: number): Observable<Array<CookiesConsentCookieSelector>> {
        const ret = new Observable<Array<CookiesConsentCookieSelector>>((observer) => {
            this.getAllCookies(false)
                .pipe(take(1))
                .subscribe((allCookies) => {
                    let locRet: Array<CookiesConsentCookieSelector> = null;
                    if (!allCookies) {
                        //
                    } else if (!groupId) {
                        locRet = allCookies;
                    } else {
                        locRet = allCookies.filter(c => c.groupId == groupId);
                    }
                    observer.next(locRet);
                    console.log(locRet);
                    observer.complete();
                });
        });

        return ret;
    }

    getCookie(cookieId: number): Observable<CookiesConsentCookieSelector> {
        const ret = new Observable<CookiesConsentCookieSelector>((observer) => {
            this.getAllCookies(false)
                .pipe(take(1))
                .subscribe((allCookies) => {
                    let locRet: CookiesConsentCookieSelector = null;
                    if (!allCookies) {
                        //
                    } else {
                        locRet = allCookies.find(c => c.id == cookieId);
                    }
                    observer.next(locRet);
                    observer.complete();
                });
        });

        return ret;
    }

    getScript(scriptId: number): Observable<CookiesConsentScriptSelector> {
        const ret = new Observable<CookiesConsentScriptSelector>((observer) => {
            this.getScripts(false)
                .pipe(take(1))
                .subscribe((scripts) => {
                    let locRet: CookiesConsentScriptSelector = null;
                    if (!scripts) {
                        //
                    } else {
                        locRet = scripts.find(s => s.id == scriptId);
                    }
                    observer.next(locRet);
                    observer.complete();
                });
        });

        return ret;
    }

    getGroupName(groupId: number): Observable<string> {
        const ret = new Observable<string>((observer) => {
            this.getAllGroups(false)
                .pipe(take(10))
                .subscribe((allGroups) => {

                    let retName: string = null;

                    if (allGroups) {
                        if (allGroups.find(g => g.id == groupId) &&
                            allGroups.find(g => g.id == groupId).langs.find(l => l.cultureId == this.seSvc.culture.cultureId))
                        retName = allGroups.find(g => g.id == groupId).langs.find(l => l.cultureId == this.seSvc.culture.cultureId).name;
                    }

                    observer.next(retName);
                });
        });

        return ret;
    }



    getCookiesCount(groupId: number): Observable<number> {
        const ret = new Observable<number>((observer) => {

            this.getCookies(groupId == -1 ? null : groupId)
                .pipe(take(1))
                .subscribe((groupCookies) => {
                    observer.next(groupCookies.length);
                });
        });
        return ret;
    }

    saveCookie(cookie: CookiesConsentCookieSelector): Observable<number> {

        const ret = new Observable<number>((observer) => {

            this.http.post<number>(`api/cookies-consent/save-cookie`, cookie)
                .pipe(take(1))
                .subscribe((res) => {
                    this.getAllCookies(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            observer.next(res);
                        });
                });
        });
        return ret;
    }

    saveScript(cookie: CookiesConsentScriptSelector): Observable<number> {

        const ret = new Observable<number>((observer) => {

            this.http.post<number>(`api/cookies-consent/save-script`, cookie)
                .pipe(take(1))
                .subscribe((res) => {
                    this.getScripts(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            observer.next(res);
                        });
                    this.getAllCookies(true);
                });
        });
        return ret;
    }

    deleteCookie(cookieId: number) {
        const ret = new Observable<number>((observer) => {

            this.http.get<number>(`api/cookies-consent/delete-cookie/${cookieId}`)
                .pipe(take(1))
                .subscribe((res) => {
                    this.getAllCookies(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            observer.next(res);
                        });
                });
        });
        return ret;
    }

    deleteScript(scriptId: number) {
        const ret = new Observable<number>((observer) => {

            this.http.get<number>(`api/cookies-consent/delete-script/${scriptId}`)
                .pipe(take(1))
                .subscribe((res) => {
                    this.getScripts(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            observer.next(res);
                        });
                });
        });
        return ret;
    }

    getScripts(forceRedownload: boolean): Observable<Array<CookiesConsentScriptSelector>> {
        if (forceRedownload || !this.scriptsCalled) {
            this.scriptsCalled = true;
            this.scripts.clearBuffer();
            this.http.get<Array<CookiesConsentScriptSelector>>('api/cookies-consent/get-scripts')
                .pipe(take(1))
                .subscribe((res) => {
                    this.scripts.next(res);
                });
        }

        return this.scripts;
    }

    getVendors(forceRedownload: boolean): Observable<Array<CookiesConsentVendorSelector>> {
        if (forceRedownload || !this.vendorsCalled) {
            this.vendorsCalled = true;
            this.vendors.clearBuffer();
            this.http.get<Array<CookiesConsentVendorSelector>>('api/cookies-consent/get-vendors')
                .pipe(take(1))
                .subscribe((res) => {
                    this.vendors.next(res);
                });
        }

        return this.vendors;
    }

    saveVendors(vendors:Array<CookiesConsentVendorSelector>): Observable<boolean> {
        const ret = new Observable<boolean>((observer) => {

            this.http.post<boolean>(`api/cookies-consent/save-vendors`, vendors)
                .pipe(take(1))
                .subscribe((res) => {
                    this.getVendors(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            observer.next(res);
                        });
                });
        });

        return ret;
    }
}