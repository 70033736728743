/**
 * Provides route guarding mechanism for secured routes
 * @description
 * If the user wants to navigate by any means to the route, which is secured by this guard
 * the guard either brings up the dialog with warning or it navigates to "you must login" route
 * https://angular.io/docs/ts/latest/api/router/index/CanActivate-interface.html
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {DigestService} from "./digest.service";
import {IRight} from "../interfaces/general";

export interface IAccess {
    canGo: boolean;
    right: number;
}

@Injectable()
export class RouteGuardService implements CanActivate {

    constructor(private router: Router, private digestSvc: DigestService) { }

    private isRouteAllowed(token: string, route: ActivatedRouteSnapshot): IAccess {

        if (!this.digestSvc.userRights) return {canGo: false, right: -1};

        // TEMPORARY HACK
        if(token.indexOf('fast')>-1 && this.digestSvc.userName=='DemoSC'){
            return {canGo: false , right: 0};
        }

        let currentRightValue: number = 0;
        let foundRights: IRight[] = this.digestSvc.userRights.rights.filter(f => f.rightToken == token || f.rightToken == 'all');

        if (foundRights.length === 0) return {canGo: false, right: 0};

        if (route.data['developerOnly'] && !this.digestSvc.userRights.developer) return {canGo: false, right: 4};

        let namedRight = foundRights.filter(f => f.rightToken === token);
        let versatileRight = foundRights.filter(f => f.rightToken === 'all');

        if (namedRight.length > 0) currentRightValue = namedRight[0].right;
        else currentRightValue = versatileRight[0].right;

        if (/rights/.test(token)) {

            let maximumRightsFound = foundRights.filter(f => f.right === 3).length > 0;
            return {canGo: maximumRightsFound || this.digestSvc.userRights.developer, right: this.digestSvc.userRights.developer ? 3 : currentRightValue};

        } else {
            return {canGo: currentRightValue > 0 || this.digestSvc.userRights.developer, right: this.digestSvc.userRights.developer ? 3 : currentRightValue};
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

        /**
         * IRight.rightToken: string - either "all", or name of requested route without slashes, e.g. "banners"
         * IRight.right: number - level of access:
         *      0 - no access
         *      1-  read-only
         *      2 - read/write, including delete
         *      3 - can edit rights of other users
         *      4 - fake value meaning developer
         */

        let token: string = state.url.replace(/\//, '');

        let access: IAccess = this.isRouteAllowed(token, route);

        let canGo: boolean = access.canGo;
        let minRight: number = access.right;

        if (!canGo && minRight == -1) {
            this.router.navigateByUrl('/pa')
                .then(() => {
                    return false
                });
        } else if (!canGo && minRight >= 0) {
            this.router.navigateByUrl('/not-authorized')
                .then(() => {
                    return false
                });
        } else {
            // here we let him go
            // the rest is being evaluated in the particular route init
            this.digestSvc.currentRight = minRight;
            return true;
        }

    }

}
