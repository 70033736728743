import {Directive, HostListener} from "@angular/core";

declare const $: any;

@Directive({
    selector: '[sideDrawerToggle]'
})

export class SideDrawerToggleDirective {

    constructor() {}
    @HostListener('click') onClick() {
        const sideBar = $('.page__side-bar').first();
        const active = sideBar.hasClass('page__side-bar--active');

        if (active) {
            sideBar.removeClass('page__side-bar--active');
        }
        else {
            sideBar.addClass('page__side-bar--active');
        }
    }
}