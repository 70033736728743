import {Component, ViewEncapsulation} from "@angular/core";
import {AdminMenuGroup, AdminMenuItem} from "../../interfaces/general";
import {MenuService} from "./menu.service";

@Component({
    selector: 'cmp-menu',
    templateUrl: '../../tpl/menu.html',
    styleUrls: ['../../assets/css/3-layout/menu.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class MenuComponent {

    menuItemsOrGroups: Array<AdminMenuItem | AdminMenuGroup> = [];

    constructor(private mnuSvc: MenuService) {
        this.menuItemsOrGroups = this.mnuSvc.menuItemsOrGroups;
    }

}