/**
 * @description
 * Serves as a container for front end application settings
 * Place only things that are really needed
 */
import {EventEmitter, Injectable} from '@angular/core';
import {ICulture, ISettings, SettingItem} from "../interfaces/general";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ICurrency} from "../modules/index/common";
import {IntegrationService} from "../interceptors/integration.service";
import {map, tap} from "rxjs/operators";
import { ICountryLang } from "../modules/country/common";


export function GetLocaleFactory(seSvc: SettingsService) {
    return seSvc.getLocale()
}

@Injectable()
export class SettingsService {

    private _sen: Object;

    get sen(): Object {
        return this._sen;
    }

    set sen(value: Object) {
        this._sen = value;
    }


    cultureChanged: EventEmitter<ICulture>;
    settings: ISettings;
    sharedAppDefaults: any;
    culture: ICulture;
    currencyId: number;
    defCulture: ICulture;
    defCurrency: ICurrency;
    defCurrencyId: number;
    // on shop application this serves for pre-authentication purposes
    // left here for FlexHttp compatibility
    comAllowed: boolean = true;

    constructor(private saSvc: SharedAppSettings, private http: HttpClient, private iSvc: IntegrationService) {

        this.settings = this.initiateSettingObject();
        this.sharedAppDefaults = saSvc.Defaults;
        this.cultureChanged = new EventEmitter();

        // Culture and Currency are retrieved from the shared application settings
        // and valid either until user logs in or changes them manually
        this.defCurrencyId = this.sharedAppDefaults['CurrencyId'];
        this.defCulture = this.settings.cultures.filter((c) => c.cultureId == this.sharedAppDefaults['CultureId'])[0];
        this.defCurrency = this.settings.currencies.filter((c) => c.Id == this.defCurrencyId)[0];

        let cult = <ICulture>loadFromSession('cult');
        if (cult) {
            this.culture = cult;
        } else {
            this.culture = this.defCulture;
            saveToSession('cult', this.culture)
        }
        let curr = loadFromSession('curr');
        if (curr) {
            this.currencyId = curr;
        } else {
            this.currencyId = this.defCurrencyId;
            saveToSession('curr', this.currencyId)
        }
    }

    initiateSettingObject(): ISettings {
        return {
            "availDigitInfo": "1.0-0",
            "dateFormat": "medium",
            "countries": [
                {
                    "id": 326,
                    "sysName": 'Czech Republic',
                    "displayName": {
                        "34": "Česká republika",
                        "102": "Česká republika",
                        "50": "Czech Republic"
                    }
                },
                {
                    "id": 462,
                    "sysName": 'Slovakia',
                    "displayName": {
                        "34": "Slovensko",
                        "102": "Slovensko",
                        "50": "Slovakia"
                    }
                }
            ],
            "cultures": [
                {
                    "code": "cs-CZ",
                    "uiCode": "CZ",
                    "cultureId": 34,
                    "name": "Česky",
                    "currencyCode": "CZK"
                },
                {
                    "code": "de-DE",
                    "uiCode": "DE",
                    "name": "Německy",
                    "currencyCode": "EUR",
                    "cultureId": 65
                },
                {
                    "code": "en-US",
                    "uiCode": "EN",
                    "name": "Anglicky",
                    "currencyCode": "USD",
                    "cultureId": 50
                }
            ],
            currencies: [
                {
                    Id: 3,
                    Name: 'CZK',
                    DisplayName: 'Kč',
                    Code: 'CZK',
                    RoundTo: 0,
                    Enabled: true,
                    IsMain: true
                },
                {
                    Id: 2,
                    Name: 'EUR',
                    DisplayName: '€',
                    Code: 'EUR',
                    RoundTo: 2,
                    Enabled: true,
                    IsMain: false
                },
                {
                    Id: 1,
                    Name: 'USD',
                    DisplayName: '$',
                    Code: 'USD',
                    RoundTo: 2,
                    Enabled: false,
                    IsMain: false
                }
            ],
            defaultCultureId: 34,
            defaultCurrencyId: 3,
            "articleRoots": [
                {
                    "rootName": "Articles"
                }
            ],
            "validationPatterns": [
                {
                    "name": "email",
                    "cultureId": 34,
                    "pattern": /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,8})$/,
                    "isDefault": true
                },
                {
                    "name": "phone",
                    "cultureId": 34,
                    "pattern": /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                    "isDefault": true
                },
                {
                    "name": "phone",
                    "cultureId": 50,
                    "pattern": /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/
                },
                {
                    "name": "zipCode",
                    "cultureId": 34,
                    "pattern": /^\d{3} ?\d{2}$/,
                    "isDefault": true
                },
                {
                    "name": "zipCode",
                    "cultureId": 50,
                    "pattern": /^\d{3} ?\d{2}$/
                },
                {
                    "name": "naturalNumber",
                    "cultureId": 34,
                    "pattern": /^[1-9][0-9]*$/
                },
                {
                    "name": "price",
                    "cultureId": 34,
                    "pattern": /^[0-9]{1,9}(?:\.[0-9]{1,4})?$/
                }
            ],
            "primeng": {
                "locales": {
                    "cs": {
                        "firstDayOfWeek": 1,
                        "dayNames": ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
                        "dayNamesShort": ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
                        "dayNamesMin": ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
                        "monthNames": ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
                        "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        "today": 'Dnes',
                        "clear": 'Reset'
                    }
                }
            },
            "loginCookieExpirationHours": null,
            "imageServerPathPrefix": this.saSvc.Defaults.ImgServer,
            "newsletterBannerFotoCache": "/fotocache/newsletters/template-1",
            "newsletterProductFotoCache": "/fotocache/mid/images/produkty/"
        };
    }

    setCulture(cultureId: number, currencyId?: number) {

        if (this.culture.cultureId === cultureId && this.currencyId == currencyId) return;

        if (currencyId) this.currencyId = currencyId;

        this.culture = this.settings.cultures.filter(c => c.cultureId == cultureId)[0];
        saveToSession('cult', this.culture);

        this.cultureChanged.emit(this.culture)
    }

    private setStartUpSettings(items: SettingItem[]) {
        this.settings.imageServerPathPrefix = items.filter(f => f.name === "ImageServer")[0].value;

    }

    private setUpApplication(input: any): any {
        this.setStartUpSettings(input['StartUpSettings']);
        this.settings.countries = input['Countries'].map((m: any) => {
            return {
                id: m.id,
                sysName: m.sysName,
                displayName: this.TranslateCoutriesLang(m.cultures),
                phonePreset: m.phonePreset
            }
        });
        return this.makeSentenceObject(input['Translations']);
    }

    private TranslateCoutriesLang(countriesLang: ICountryLang[]) {
        let dict = {};
        dict = countriesLang.reduce((dict: any, country) => (dict[country.cultureId] = country.name, dict), {});

        return dict;
    }

    getAllTranslations(): any {
        /**
         * IMPORTANT
         * This is the only place, where standard Http service is used instead of FlexHttp
         * This is due circular reference - FlexHttp takes SettingsService as dependency
         * and DI would not allow using FlexHttp here. LEAVE IT AS IS
         * @type {{headers: Headers}}
         */

        let options = {headers: new HttpHeaders()};
        options.headers.set('F-CC', `cu:${this.culture.cultureId},cy:${this.currencyId}`);

        this.iSvc.httpRegional = {
            comAllowed: this.comAllowed,
            cultureId: this.culture.cultureId,
            currencyId: this.currencyId
        };

        let result = this.http.get(`api/lang/${this.culture.cultureId}`, options)
            .pipe(
                map(res => this.setUpApplication(res)),
                tap(res => {
                    this.sen = res;
                    this.iSvc.httpRegional.comAllowed = this.comAllowed;
                })
            );
        return result.toPromise()
    }

    getLocale(): string {
        return this.culture.code;
    }

    makeSentenceObject(input: Object[]): Object {
        let tmp: any = {};
        if (input) {
            input.forEach((e: any) => tmp[e['Key']] = e['Value']);
        }
        return tmp;
    }

    getPrimeNgLocale(code: string): any {
        return this.settings.primeng.locales[code];
    }

    getPattern(name: string, cultureId: number): RegExp {
        let item = this.settings.validationPatterns.find(q => q.name == name && q.cultureId == cultureId);
        if (!item) {
            return null;
        }
        return item.pattern;
    }

    getInstalledCulturesQueryString(): string {
        return this.settings.cultures.map((culture) => {
            return `installedCultureIds=${culture.cultureId}`;
        }).join('&');
    }

    getInstalledCulturesIds(): Array<number> {
        return this.settings.cultures.map(culture => culture.cultureId);
    }

    get currency(): ICurrency {
        return this.settings.currencies.find(c => c.Id == this.currencyId);
    }

    public CreateEditorSettings(): any {
        return {
            height: 175,
            tabsize: 2,
            lang: 'cs-CZ',
            imageServerPathPrefix: this.settings.imageServerPathPrefix,
            toolbar: [
                ['para', ['style']],
                ['misc', ['undo', 'redo']],
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['fontname', 'strikethrough', 'superscript', 'subscript']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['height', ['height']],
                ['insert', ['picture', 'link', 'video', 'table', 'hr']],
                ['misc', ['fullscreen', 'codeview']]
            ]
        }
    }
}
