import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ArticleOrderChangeRequest, ArticleSelector, MoveDirection} from "../modules/articles/common";
import {Observable} from "rxjs";

@Injectable()
export class ArticleService {
    constructor(private http: HttpClient) {
    }

    getArticlesTree(code?: string): Observable<ArticleSelector[]> {

        let url = 'api/articles/tree';
        if (code) {
            url += `/${code}`;
        }

        return this.http.get<ArticleSelector[]>(url);
    }

    moveArticle(articleOrderChangeRequest:ArticleOrderChangeRequest): Observable<boolean> {
        let url = `api/articles/change-order-${articleOrderChangeRequest.direction==MoveDirection.lower?'down':'up'}/${articleOrderChangeRequest.id}`;
        return this.http.get<boolean>(url);
    }

    deleteCommonArticle(id:number): Observable<boolean> {
        let url = `api/articles/delete/${id}`;
        return this.http.get<boolean>(url);
    }

    deleteUndeleteableArticle(id:number): Observable<boolean> {
        let url = `api/articles/delete-undeleteable/${id}`;
        return this.http.get<boolean>(url);
    }

    getOne(id:number):Observable<ArticleSelector>{
        let url = `api/articles/get-one/${id}`;
        return this.http.get<ArticleSelector>(url);
    }

    save(article:ArticleSelector):Observable<number>{
        return this.http.post<number>('api/articles/save',article);
    }


}