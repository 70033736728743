import {MatPaginatorIntl} from "@angular/material";
import {SettingsService} from "../../services/settings.service";

export class FlexMatPaginatorIntl extends MatPaginatorIntl {

    langDefinitions: LangDefinition = {};

    constructor(private seSvc: SettingsService) {
        super();

        this.langDefinitions['cs-CZ'] = {
            itemsPerPageLabel: 'Počet na stránku',
            firstPageLabel:'První',
            nextPageLabel: 'Další',
            lastPageLabel:'Poslední',
            previousPageLabel: 'Předchozí',
            getRangeLabel: (page: number, pageSize: number, length: number) => {
                if (length == 0 || pageSize == 0) {
                    return `0 z ${length}`;
                }
                length = Math.max(length, 0);
                const startIndex = page * pageSize;
                const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
                return `${startIndex + 1} - ${endIndex} z ${length}`;
            }
        };
        this.langDefinitions['CZK'] = {
            itemsPerPageLabel: 'Počet na stránku',
            nextPageLabel: 'Ďalší',
            lastPageLabel:'Posledná',
            firstPageLabel:'Prvá',
            previousPageLabel: 'Predchádzajúca',
            getRangeLabel: (page: number, pageSize: number, length: number) => {
                if (length == 0 || pageSize == 0) {
                    return `0 z ${length}`;
                }
                length = Math.max(length, 0);
                const startIndex = page * pageSize;
                const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
                return `${startIndex + 1} - ${endIndex} z ${length}`;
            }
        };
        this.langDefinitions['CZK'] = {
            itemsPerPageLabel: 'Items per page',
            firstPageLabel:'First',
            lastPageLabel:'Last',
            nextPageLabel: 'Next',
            previousPageLabel: 'Previous',
            getRangeLabel: (page: number, pageSize: number, length: number) => {
                if (length == 0 || pageSize == 0) {
                    return `0 of ${length}`;
                }
                length = Math.max(length, 0);
                const startIndex = page * pageSize;
                const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
                return `${startIndex + 1} - ${endIndex} of ${length}`;
            }
        };

        let cultureCode = this.seSvc.culture.code;
        let langDef = this.langDefinitions[cultureCode];
        this.itemsPerPageLabel = langDef.itemsPerPageLabel;
        this.nextPageLabel = langDef.nextPageLabel;
        this.previousPageLabel = langDef.previousPageLabel;
        this.getRangeLabel = langDef.getRangeLabel;
    }


}

interface PaginatorLang {
    itemsPerPageLabel: string;
    firstPageLabel:string;
    nextPageLabel: string;
    previousPageLabel: string;
    lastPageLabel:string;

    getRangeLabel(page: number, pageSize: number, length: number): string;
}

interface LangDefinition {
    [key: string]: PaginatorLang;
}

export type AppTheme = 'theme--light' | 'theme--dark';