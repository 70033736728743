import {Injectable} from "@angular/core";
///////////////////////////////////////////

/**
 * Automatically generated based on AppModule from SharedSettings dll
 */

@Injectable()
export class SharedAppSettings 
    {
Defaults = {
CultureId: 34,
CurrencyId: 3,
AllowAnonymousCart: false,
ImgServer: 'https://img.flex.scdev.cz/',
ShopUrl: 'https://www.flex.scdev.cz/',
PreAuthUserName: 'tester',
DefaultUnitId: 8
};
AddressType = {
Invoice: 'Invoice',
Delivery: 'Delivery'
}
}




/**
 * Automatically generated based on ArticlesModule from SharedSettings dll
 */

@Injectable()
export class SharedArticlesSettings 
    {

}




/**
 * Automatically generated based on NewslettersModule from SharedSettings dll
 */

@Injectable()
export class SharedNewslettersSettings 
    {
Defaults = {
TestMailDirectoryId: 1
}
}




/**
 * Automatically generated based on OrdersModule from SharedSettings dll
 */

@Injectable()
export class SharedOrdersSettings 
    {
ItemType = {
PRODUCT: 'PRODUCT',
GIFT: 'GIFT',
PAYMENT: 'PAYMENT',
TRANSPORT: 'TRANSPORT',
COUPON: 'COUPON'
}
}

