import {Injectable} from "@angular/core";
import {AdminMenuGroup, AdminMenuItem} from "../../interfaces/general";
import {Router} from "@angular/router";
import {DigestService} from "../../services/digest.service";

@Injectable()
export class MenuService {

    get flatMenuItems(): AdminMenuItem[] {
        return this._flatMenuItems;
    }

    private _flatMenuItems: AdminMenuItem[] = [];

    get menuItemsOrGroups(): Array<AdminMenuItem | AdminMenuGroup> {
        return this._menuItemsOrGroups;
    }

    private _menuItemsOrGroups: Array<AdminMenuItem | AdminMenuGroup> = [];

    get menuGroups(): AdminMenuGroup[] {
        return this._menuGroups;
    }

    set menuGroups(value: AdminMenuGroup[]) {
        this._menuGroups = value;
    }

    private _menuGroups: AdminMenuGroup[] = [];

    constructor(private router: Router, private digestSvc: DigestService) {
        if (this.digestSvc.userRights) this.processMenu();
    }

    private itemHasRight(itemRoute: string): boolean {
        // TEMPORARY HACK:
        if(itemRoute=='fast' && this.digestSvc.userName=='DemoSC'){
            return  false;
        }

        let minRight: number = 0;
        if (itemRoute === 'rights') {
            minRight = 2;
        }

        const versatileRight = this.digestSvc.userRights.rights.filter(f => f.rightToken === 'all')[0].right;
        let currentRight = 0;
        const filteredRights = this.digestSvc.userRights.rights.filter(f => f.rightToken === itemRoute);
        if (filteredRights.length > 0) {
            currentRight = filteredRights[0].right;
        } else {
            currentRight = versatileRight;
        }
        let resultRight = 0;
        if (currentRight >= versatileRight) {
            resultRight = currentRight
        } else {
            resultRight = Math.min(currentRight, versatileRight)
        }
        return resultRight > minRight;
    }

    private itemSatisfiesDeveloperOnly(item: AdminMenuItem): boolean {
        if (!item.developerOnly) return true;
        else return this.digestSvc.developerMode;
    }

    private filterMenuGroupsToDisplay(inputGroups: AdminMenuGroup[]): AdminMenuGroup[] {
        for (let i = 0; i < inputGroups.length; i++) {
            inputGroups[i].subItems = inputGroups[i].subItems
                .filter(f => this.itemHasRight(f.mainRoute))
                .filter(f => this.itemSatisfiesDeveloperOnly(f));
        }
        return inputGroups;
    }

    private processMenu(): void {
        this._menuGroups.push(
            {
                groupName: 'Objednávky',
                icon: 'shopping_cart',
                subItems: [],
                globalOrder: 1
            },
            {
                groupName: 'Zboží',
                icon: 'shop',
                subItems: [],
                globalOrder: 2
            },
            {
                groupName: 'Marketing',
                icon: 'trending_up',
                subItems: [],
                globalOrder: 4
            },
            {
                groupName: 'Aplikace 3. stran',
                icon: 'apps',
                subItems: [],
                globalOrder: 5
            },
            {
                groupName: 'Inspirace - Blog',
                icon: 'apps',
                subItems: [],
                globalOrder: 12
            },
            {
                groupName: 'Nastavení',
                icon: 'settings_applications',
                subItems: [],
                globalOrder: 6
            },
            {
                groupName: 'Developer',
                icon: 'build',
                subItems: [],
                globalOrder: 8
            }
        );
        let tempItems: AdminMenuItem[] = [];
        this.router.config
            .filter(f => f.data && f.data['titleKey'])
            .forEach((elem) => {
                tempItems.push({
                    developerOnly: elem.data ? elem.data['developerOnly'] : false,
                    group: elem.data ? elem.data['group'] : '',
                    mainRoute: elem.path,
                    titleKey: elem.data ? elem.data['titleKey'] : '',
                    iconClass: elem.data ? elem.data['iconClass'] : '',
                    globalOrder: elem.data? elem.data['globalOrder'] : Number.MAX_SAFE_INTEGER,
                    localOrder: elem.data? elem.data['localOrder'] : Number.MAX_SAFE_INTEGER,
                });
            });

        //additional menu items not based on routes:
        // tempItems.push({
        //     developerOnly: false,
        //     titleKey: 'Kategorie produktů',
        //     iconClass: 'fas fa-sitemap',
        //     group: 'Nastavení',
        //     mainRoute: "categories/settings",
        //     globalOrder: Number.MAX_SAFE_INTEGER,
        //     localOrder: 4
        // });

        this._flatMenuItems = tempItems
            .filter(f => this.itemHasRight(f.mainRoute))
            .filter(f => this.itemSatisfiesDeveloperOnly(f));

        const menuItems = tempItems
            .filter(f => !f.group || f.group === '')
            .filter(f => this.itemHasRight(f.mainRoute))
            .filter(f => this.itemSatisfiesDeveloperOnly(f));

        let menuGroups = this.menuGroups.map((elem) => {
            elem.subItems = tempItems.filter(f => f.group === elem.groupName);
            return elem;
        });

        menuGroups = this.filterMenuGroupsToDisplay(menuGroups)
            .filter(f => f.subItems.length > 0);

        menuGroups.forEach(elem => {
            elem.subItems = elem.subItems
                .sort((a: AdminMenuItem, b: AdminMenuItem) => {
                    return a.localOrder - b.localOrder
                })
        });

        this._menuItemsOrGroups = [...menuGroups, ...menuItems];
        this._menuItemsOrGroups = this._menuItemsOrGroups
            .sort((a: AdminMenuItem | AdminMenuGroup, b: AdminMenuItem | AdminMenuGroup) => {
                return a.globalOrder - b.globalOrder
            });
    }

}
