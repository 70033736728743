export interface ArticleSelector {
    id: number;
    parentId: number;
    disabled: boolean;
    validFrom: Date;
    validTo: Date;
    order: number;
    loginState: LoginState;
    imagePath: string;
    langs: ArticleLangSelector[];
    isSiteMap: boolean;
    isUndeleteAble: boolean;
    isContactForm: boolean;
    reallyDelete: boolean;
    code:string;
    children: ArticleSelector[];
}

export interface ArticleLangSelector {
    id: number;
    articleId: number;
    cultureId: number;
    name: string;
    title: string;
    imageTitle: string;
    annotation: string;
    body: string;
    extraData: string;
    created: Date;
    lastUpdate: Date;
    seoDescription: string;
    seoUrl: string;
    targetUrl: string;
    creatorUsername: string
    lastUpdaterUsername: string;
    idPath: string;
    enabled: boolean;
}

export type productSelectionMode = 'manual' | 'filter';

export class productSelectionOption {
    label: string;
    value: productSelectionMode;
}


export interface ArticleListNode {
    id: number;
    isFirst: boolean;
    isLast: boolean;
    name: string;
    namePath: string;
    showAsDisabled: boolean;
    children?: ArticleListNode[];
}

export enum LoginState {
    all = "all",
    notLogged = 'notLogged',
    logged = 'logged'
}

export enum MoveDirection {
    higher,
    lower
}

export interface ArticleOrderChangeRequest {
    id: number,
    direction: MoveDirection
}

export function searchArticlesTree(id: number, articles: ArticleSelector[]): ArticleSelector {
    if (!id || !articles) {
        return null;
    }

    for (let i = 0; i < articles.length; i++) {
        if (articles[i].id == id) {
            return articles[i];
        }
        if (articles[i].children) {
            let foundChild = searchArticlesTree(id, articles[i].children);
            if (foundChild) {
                return foundChild;
            }
        }
    }

    return null;
}


export interface ArticleSelectorDialogData {
    disableSubTree: number
}
