import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MenuToggleDirective} from "./menu-toggle.directive";
import {SideDrawerToggleDirective} from "./side-drawer-toggle.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [MenuToggleDirective, SideDrawerToggleDirective],
    exports: [MenuToggleDirective, SideDrawerToggleDirective]
})
export class MenuHelpersModule {}