/**
 * @description
 * General service for handling several kinds of errors
 * The only exception is HTTP 403 - Forbidden, which is handled by force login in {@link DigestService}
 */
import {Injectable, EventEmitter} from "@angular/core";
import {HttpResponse} from "@angular/common/http";

export interface ErrorObject {
    type: 'error',
    status: number,
    message: string
}


@Injectable()
export class ErrorService {

    errorEmitter: EventEmitter<ErrorObject>;

    constructor() {
        this.errorEmitter = new EventEmitter()
    }

    handleError(err: HttpResponse<any>): void {
        this.errorEmitter.emit({type: 'error', status: err.status, message: err.statusText})
    }
}