import {Injectable, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";
import {RouteTranslation} from "../../interfaces/general";

export const routes: Routes = [
    /**
     * OPEN ROUTES
     * these routes MUST NOT use RouteGuardService
     */
    {path: 'pa', loadChildren: 'app/modules/pre-auth/pre-auth.module#PreAuthModule'},
    {path: 'not-authorized', loadChildren: 'app/modules/warnings/warnings.module#WarningsModule'},
    /**
     * SECURED ROUTES - always use {@link RouteGuardService}
     */
    {path: '', data: {globalOrder: 0, developerOnly: false, titleKey: 'Dashboard', iconClass: 'dashboard'}, loadChildren: 'app/modules/index/index.module#IndexModule', pathMatch: 'full', canActivate: [RouteGuardService]},
    {path: 'translations', data: {localOrder: 0, developerOnly: false, titleKey: 'Překlady', iconClass: 'fa fa-language', group: 'Nastavení'}, loadChildren: 'app/modules/translations/translations.module#TranslationModule', canActivate: [RouteGuardService]},
    {path: 'articles', data: {localOrder: 0, developerOnly: false, titleKey: 'Články', iconClass: 'far fa-newspaper', group: 'Marketing'}, loadChildren: 'app/modules/articles/articles.module#ArticlesModule', canActivate: [RouteGuardService]},
    {path: 'parameters', data: {localOrder: 2, developerOnly: false, titleKey: 'Parametry produktů', iconClass: 'fas fa-sliders-h', group: 'Zboží'}, loadChildren: 'app/modules/parameters/parameters.module#ParametersModule', canActivate: [RouteGuardService]},
    {path: 'options', data: {localOrder: 1, developerOnly: false, titleKey: 'Systémová nastavení', iconClass: 'fas fa-wrench', group: 'Nastavení'}, loadChildren: 'app/modules/options/options.module#OptionsModule', canActivate: [RouteGuardService]},
    {path: 'banners', data: {localOrder: 3, developerOnly: false, titleKey: 'Bannery', iconClass: 'far fa-images', group: 'Marketing'}, loadChildren: 'app/modules/banners/banners.module#BannersModule', canActivate: [RouteGuardService]},
    {path: 'emails', data: {localOrder: 3, developerOnly: false, titleKey: 'Emaily', iconClass: 'far fa-envelope', group: 'Nastavení'}, loadChildren: 'app/modules/emails/emails.module#EmailsModule', canActivate: [RouteGuardService]},
    {path: 'products', data: {localOrder: 0, developerOnly: false, titleKey: 'Produkty', iconClass: 'shop', group: 'Zboží'}, loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [RouteGuardService]},
    {path: 'import-products', loadChildren: 'app/modules/products/products.module#ProductsModule', canActivate: [RouteGuardService]},
    // {path: 'newsletters', data: {localOrder: 4, developerOnly: false, titleKey: 'Newslettery', iconClass: 'far fa-file-alt', group: 'Marketing'}, loadChildren: 'app/modules/newsletters/newsletters.module#NewslettersModule', canActivate: [RouteGuardService]},
    // {path: 'newsletter-mail-groups', data: {localOrder: 5, developerOnly: false, titleKey: 'E-mailové adresáře', iconClass: 'far fa-address-book', group: 'Marketing'}, loadChildren: 'app/modules/newsletter-mail-groups/newsletter-mail-groups.module#NewsletterMailGroupsModule', canActivate: [RouteGuardService]},
    {path: 'categories', data: {localOrder: 1, developerOnly: false, titleKey: 'Kategorie produktů', iconClass: 'fas fa-sitemap', group: 'Zboží'}, loadChildren: 'app/modules/categories/categories.module#CategoriesModule', canActivate: [RouteGuardService]},
    // {path: 'producers', data: {localOrder: 5, developerOnly: false, titleKey: 'Výrobci', iconClass: 'fas fa-industry', group: 'Zboží'},loadChildren: 'app/modules/producers/producer.module#ProducerModule', canActivate: [RouteGuardService]},
    {path: 'orders', data: { localOrder: 0, developerOnly: false, titleKey: 'Přehled objednávek', iconClass: 'shopping_cart', group: 'Objednávky'}, loadChildren: 'app/modules/orders/orders.module#OrdersModule', canActivate: [RouteGuardService]},
    {path: 'error-pages', data: {localOrder: 5, developerOnly: false, titleKey: 'Chybové stránky', iconClass: 'fa fa-exclamation-triangle', group: 'Nastavení'}, loadChildren: 'app/modules/error-pages/error-pages.module#ErrorPagesModule', canActivate: [RouteGuardService]},
    {path: 'shipments', data: {localOrder: 2, developerOnly: false, titleKey: 'Doprava a platba', iconClass: 'fa fa-truck', group: 'Nastavení' }, loadChildren: 'app/modules/shipment-payment/shipment-payment.module#ShipmentPaymentModule', canActivate: [RouteGuardService]},
    {path: 'reviews', data: {localOrder: 6, developerOnly: false, titleKey: 'Recenze produktů', iconClass: 'fab fa-discourse', group: 'Zboží'}, loadChildren: 'app/modules/reviews/reviews.module#ReviewsModule', canActivate: [RouteGuardService]},
    {path: 'rights', data: {globalOrder: 9, developerOnly: false, titleKey: 'Uživatelské účty', iconClass: 'person_add'}, loadChildren: 'app/modules/rights/rights.module#RightsModule', canActivate: [RouteGuardService]},
    {path: 'sitemap', data: {localOrder: 1, developerOnly: true, titleKey: 'Soubory Sitemap', iconClass: 'fas fa-map-signs', group: 'Developer'}, loadChildren: 'app/modules/sitemap/sitemap.module#SiteMapModule', canActivate: [RouteGuardService]},
    {path: 'customers', data: {globalOrder: 3, developerOnly: false, titleKey: 'Zákazníci', iconClass: 'people'}, loadChildren: 'app/modules/customers/customers.module#CustomersModule', canActivate: [RouteGuardService]},
    {path: 'pricelists', data: {localOrder: 3, developerOnly: false, titleKey: 'Ceníky', iconClass: 'fas fa-dollar-sign', group: 'Zboží'}, loadChildren: 'app/modules/pricelists/pricelists.module#PriceListsModule', canActivate: [RouteGuardService]},
    {path: 'general-config', data: {localOrder: 0, developerOnly: false, titleKey: 'Obecné nastavení', iconClass: 'fas fa-cog', group: 'Nastavení'}, loadChildren: 'app/modules/general-config/general-config-tabs.module#GeneralConfigTabsModule', canActivate: [RouteGuardService]},
    {path: 'feed-list', data: {localOrder: 0, developerOnly: false, titleKey: 'XML feedy', iconClass: 'fa fa-rss', group: 'Aplikace 3. stran'}, loadChildren: 'app/modules/feeds-simple/feeds-simple.module#FeedsSimpleModule', canActivate: [RouteGuardService]},
    {path: 'metrics', data: {localOrder: 1, developerOnly: false, titleKey: 'Měřící kódy', iconClass: 'fas fa-chart-line', group: 'Aplikace 3. stran'}, loadChildren: 'app/modules/metrics/metrics.module#MetricsModule', canActivate: [RouteGuardService]},
    {path: 'transports', data: {globalOrder: 7, developerOnly: false, titleKey: 'Přenosy', iconClass: 'sync_alt'},loadChildren: 'app/modules/transports/transports.module#TransportsModule', canActivate: [RouteGuardService]},
    {path: 'coupons', data: {localOrder: 1, developerOnly: false, titleKey: 'Slevové kupony', iconClass: 'fas fa-piggy-bank', group: 'Marketing'},loadChildren: 'app/modules/coupons/coupons.module#CouponsModule', canActivate: [RouteGuardService]},
    {path: 'gifts', data: {localOrder: 2, developerOnly: false, titleKey: 'Dárky', iconClass: 'fas fa-gift', group: 'Marketing'},loadChildren: 'app/modules/gifts/gifts.module#GiftsModule', canActivate: [RouteGuardService]},
    // {path: 'availability', data: {localOrder: 4, developerOnly: false, titleKey: 'Skladová dostupnost', iconClass: 'fa fa-tasks', group: 'Zboží'},loadChildren: 'app/modules/availability/availability.module#AvailabilityModule', canActivate: [RouteGuardService]},
    // {path: 'themes', data: {globalOrder: 10, developerOnly: false, titleKey: 'Šablony', iconClass: 'desktop_windows'}, loadChildren: 'app/modules/themes/themes.module#ThemesModule', canActivate: [RouteGuardService]},
    // {path: 'fast', data: {globalOrder: 11, developerOnly: false, titleKey: 'Objednat FastCetntrik', iconClass: 'credit_card'}, loadChildren: 'app/modules/fast/fast.module#FastModule', canActivate: [RouteGuardService]},
    {path: 'product-lists', data: {localOrder: 7, developerOnly: false, titleKey: 'Seznamy', iconClass: 'fas fa-cog', group: 'Zboží'}, loadChildren: 'app/modules/product-list/product-list.module#ProductListModule', canActivate: [RouteGuardService]},
    {path: 'benefits', data: {localOrder: 6, developerOnly: false, titleKey: 'Benefity', iconClass: 'fas fa-cog', group: 'Marketing'}, loadChildren: 'app/modules/benefits/benefits.module#BenefitsModule', canActivate: [RouteGuardService]},
    {path: 'countries', data: {localOrder: 6, developerOnly: false, titleKey: 'Státy', iconClass: 'fa fa-truck', group: 'Nastavení'}, loadChildren: 'app/modules/country/country.module#CountryModule', canActivate: [RouteGuardService]},
    {path: 'watch-dog', data: {localOrder: 7, developerOnly: false, titleKey: 'Watch dog', iconClass: 'fa fa-truck', group: 'Zboží'}, loadChildren: 'app/modules/watch-dog/watch-dog.module#WatchDogModule', canActivate: [RouteGuardService]},

    {path: 'categories-blog', data: {localOrder: 10, developerOnly: false, titleKey: 'Kategorie pro Inspirace', iconClass: 'person_add', group: 'Inspirace - Blog'}, loadChildren: 'app/modules/blog-categories/blog-categories.module#BlogCategoriesModule', canActivate: [RouteGuardService]},
    { path: 'blog', data: { localOrder: 10, developerOnly: false, titleKey: 'Inspirace - příspěvky', iconClass: 'person_add', group: 'Inspirace - Blog' }, loadChildren: 'app/modules/blog/blog.module#BlogModule', canActivate: [RouteGuardService] },
    { path: 'blog-setting', data: { localOrder: 2, developerOnly: false, titleKey: 'Inspirace - nastavení', iconClass: 'fa fa-wrench', group: 'Inspirace - Blog' }, loadChildren: 'app/modules/blog-settings/blog-settings.module#BlogSettingsModule', canActivate: [RouteGuardService] },
    { path: '**', loadChildren: 'app/modules/warnings/warnings.module#WarningsModule' },
];

/*
 * every route in use - except for '', '/pa' and '/not-authorized' MUST BE TRANSLATED HERE
 */
@Injectable()
export class RouteTranslationService {

    _routeTranslations: RouteTranslation[];

    get routeTranslations(): RouteTranslation[] {
        return this._routeTranslations;
    }

    set routeTranslations(value: RouteTranslation[]) {
        this._routeTranslations = value;
    }
    constructor () {
        this.routeTranslations = [
            // todo: leave this one in place
            {key: 'all', value: 'Vše'},
            // todo: iterate over routes and fill from there
            // todo: check if developer only when showing in rights route list
            {key: '', value: 'Dashboard'},
            {key: 'translations', value: 'Překlady'},
            {key: 'articles', value: 'Články'},
            {key: 'parameters', value: 'Parametry produktů'},
            {key: 'options', value: 'Systémové nastavení'},
            {key: 'banners', value: 'Bannery'},
            {key: 'emails', value: 'Emaily'},
            {key: 'producers', value: 'Výrobci'},
            {key: 'products', value: 'Produkty'},
            {key: 'newsletters', value: 'Newslettery'},
            {key: 'newsletter-mail-groups', value: 'E-mailové adresáře'},
            {key: 'categories', value: 'Kategorie produktů'},
            {key: 'categories-blog', value: 'Kategorie pro Inspirace'},
            {key: 'orders', value: 'Přehled objednávek'},
            {key: 'transports', value: 'Přenosy'},
            {key: 'shipments', value: 'Doprava a platba'},
            {key: 'error-pages', value: 'Chybové stránky'},
            {key: 'reviews', value: 'Recenze produktů'},
            {key: 'rights', value: 'Uživatelské účty'},
            {key: 'sitemap', value: 'Soubory Sitemap', developerOnly: true},
            {key: 'customers', value: 'Zákazníci'},
            {key: 'coupons', value: 'Slevové kupony'},
            {key: 'gifts', value: 'Dárky'},
            {key: 'general-config', value: 'Obecné nastavení'},
            {key: 'pricelists', value: 'Ceníky'},
            {key: 'feed-list', value: 'XML feedy'},
            {key: 'metrics', value: 'Měřící kódy'},
            //{key: 'fast', value: 'Objednat FastCentrik'},
            {key: 'benefits', value: 'Benefity'},
            {key: 'countries', value: 'Státy'},
            {key: 'blog-settings', value: 'Inspirace - nastavení'},
            {key: 'blog', value: 'Inspirace - příspěvky'}
        ]
    }
}

export const appRoutingProviders: any[] = [
    RouteGuardService,
    RouteTranslationService
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes,{
    //angular router does not do anything
    //when an active url is navigated
    //so if you are currently at /products/detail/177/814321
    //you change any value , you save the product and you want to
    //completely reinitialize the component just by calling
    //navigateByUrl('/products/detail/177/814321')
    //nothing will happen, no reload, no url change, no router events
    //so if you want the router to refire all events as if the url was changed
    //you have to configure such behavior like this:
    onSameUrlNavigation: 'reload' //!!!IMPORTANT!!!
    //!!!   BUT   !!!
    //this is only one half of a story
    //please go to app/modules/products/products.module.ts and see runGuardsAndResolvers
    //see this article: https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
});
