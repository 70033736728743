import {Directive, ElementRef, HostListener} from "@angular/core";

declare const $: any;

@Directive({
    selector: '[menuToggle]'
})

export class MenuToggleDirective { 

    private collapseShow: boolean = false;

    constructor(private elRef: ElementRef) {}
    @HostListener('click') onClick() {
        this.collapseShow = !this.collapseShow;
        const menuItem = $(this.elRef.nativeElement).parent('.menu__item').first();
        const arrow = $(this.elRef.nativeElement).children('.js-arrow').first();
        if (this.collapseShow) {
            menuItem.addClass('menu__item--active');
            arrow.html('keyboard_arrow_down');
        }
        else {
            menuItem.removeClass('menu__item--active');
            arrow.html('keyboard_arrow_right');
        }
    }
}