import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {ICulture, Translatable} from "../../interfaces/general";
import {LoginComponent} from "../login/login.component";
import {SettingsService} from "../../services/settings.service";
import {NavigationStart, Router} from "@angular/router";
import {DigestService} from "../../services/digest.service";
import {Subject} from "rxjs";
import {filter, takeUntil} from 'rxjs/operators';
import {loadFromLocalStorage, removeFromSessionStorage, saveToLocalStorage} from "../../helpers/cookie.helper";
import {AppTheme} from "./common";

@Component({
    selector: 'app',
    templateUrl: '../../tpl/app.html',
    styleUrls: ['../../assets/css/layout.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AppComponent extends Translatable {

    @ViewChild(LoginComponent) loginComponent: LoginComponent;

    private _userName: string;
    get userName(): string {
        return this._userName;
    }

    set userName(value: string) {
        this._userName = value;
    }

    cultureOptions: ICulture[];
    selectedCulture: ICulture;
    appTheme: AppTheme = "theme--dark";
    layoutState: boolean = false;
    menuClass: string = '';
    mainClass = '';
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService, public digestSvc: DigestService,
                private router: Router) {
        super(dataSvc, seSvc);
        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;

        const appTheme = loadFromLocalStorage('appTheme');
        if (appTheme) {
            this.appTheme = appTheme;
        }

        this.seSvc.cultureChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => location.reload());

        this.router.events
            .pipe(
                filter(evt => evt instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((evt: NavigationStart) => {
                if (!/^\/products/.test(evt.url)) {
                    removeFromSessionStorage('prodSelectedCat');
                }
            });
    }

    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCulture(id);
    }

    logOut(): void {
        this.loginComponent.logOut();
        this.router.navigateByUrl('/pa').then(() => {})
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onThemeChanged(): void {
        if (this.appTheme === "theme--dark") {
            this.appTheme = "theme--light";
        } else {
            this.appTheme = "theme--dark";
        }
        saveToLocalStorage('appTheme', this.appTheme);
    }

    onToggleLayoutState(): void {
        this.layoutState = !this.layoutState;
        if (this.layoutState) {
            this.menuClass = 'aside--active';
            this.mainClass = 'content--full';
        } else {
            this.menuClass = '';
            this.mainClass = '';
        }
    }

}
