import {Injectable} from "@angular/core";
import {Message} from "primeng/primeng";

@Injectable()
export class MessageService {

    get growlLife(): number {
        return this._growlLife;
    }

    set growlLife(value: number) {
        this._growlLife = value;
    }

    get messages(): Message[] {
        return this._messages;
    }

    set messages(value: Message[]) {
        this._messages = value;
    }

    private _messages: Message[] = [];
    private _growlLife = 3000;

    showMessageOnSave(res: boolean): void {
        if (res) {
            this.showMessageCore('Akce skončila úspěšně.', 'Nastavení', 'success')
        } else {
            this.showMessageCore('Při provádění akce došlo k chybě.', 'Nastavení', 'error')
        }
    }

    showMessage(msg: string, summary: string, severity: string): void {
        this.showMessageCore(msg, summary, severity);
    }

    private showMessageCore(msg: string, summary: string, severity: string): void {
        this.messages.push({
            severity: severity,
            summary: summary,
            detail: msg
        });

        const tim = setTimeout(() => {
            this.messages = [];
            clearTimeout(tim);
        }, this.growlLife);
    }
}