import {EventEmitter, Injectable} from "@angular/core";
import {ErrorService} from "./error.service";
import {AuthInfo, DataRequest, IAdminUserRights, IEmitterMessage, ILoginResult} from "../interfaces/general";
import {
    loadFromLocalStorage,
    removeFromLocalStorage,
    saveToLocalStorage,
    saveToSession
} from "../helpers/cookie.helper";
import {Routes} from "@angular/router";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";

declare let moment: any;
declare let StringView: any;

@Injectable()
export class DigestService {

    private _developerMode: boolean;

    get developerMode(): boolean {
        return this._developerMode;
    }

    set developerMode(value: boolean) {
        this._developerMode = value;
    }

    private _userRights: IAdminUserRights;
    get userRights(): IAdminUserRights {
        return this._userRights;
    }

    set userRights(value: IAdminUserRights) {
        this._userRights = value;
    }

    decodeUserRights(strBase64: string): IAdminUserRights {
        let urDec = StringView.makeFromBase64(strBase64);
        let urStr = new StringView(urDec.bufferView);
        return JSON.parse(urStr.toString());
    }

    _currentRight: number;
    get currentRight(): number {
        return this._currentRight;
    }

    set currentRight(value: number) {
        this._currentRight = value;
        saveToSession('cri', value);
    }

    _securedRoutes: Routes;

    get securedRoutes(): Routes {
        return this._securedRoutes;
    }

    set securedRoutes(value: Routes) {
        this._securedRoutes = value;
    }

    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;

    loginStatus: EventEmitter<IEmitterMessage>;
    querySignOut: EventEmitter<IEmitterMessage>;
    userSignedOut: EventEmitter<IEmitterMessage>;

    constructor(private http: HttpClient, private errorSvc: ErrorService) {
        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            DigestService.initNotSigned();
        } else  {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.userName = ai.userName;
        }

        let ur: string = loadFromLocalStorage('ur');
        if (ur) {
            this.userRights = this.decodeUserRights(ur);
            this.developerMode = this.userRights.developer;
        }

        this.loginStatus = new EventEmitter();
        this.querySignOut = new EventEmitter();
        this.userSignedOut = new EventEmitter();
    }

    private static initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
    }

    forceLogin(): void {
        this.querySignOut.emit({type: 'info', message: 'login required'});
    }

    /**
     * @description
     * Handles HTTP communication errors for secured routes
     * First checks for HTTP status code 403 (Forbidden) and in such case forces login
     * In case of a different error code handles error further to {@link ErrorService}
     * @param err
     * @param _req
     */
    handleError(err: HttpResponse<any>, _req: DataRequest): any {
        this.errorSvc.handleError(err);
    }

    /**
     * @description
     * Tries to log the user in. If the first call does not succeed, it is repeated with Authorization header
     * assembled from the server challenge response. Login status is the returned via emitter
     * to the {@link LoginComponent} for further proceedings
     */
    login(userName: string, pwd: string): void {

        this.requestedUri = 'api/login';
        this.requestedMethod = 'GET';

        this.userName = userName;
        this.password = pwd;
        let headers: HttpHeaders = this.getLoginHeaders();

        this.http.get<ILoginResult>(this.requestedUri, {headers: headers})
            .subscribe(
                (r) => {

                    let ai: AuthInfo = {
                        jwTokenBody: r.jwTokenBody,
                        userName: this.userName,
                        loggedIn: true,
                        validTo: moment().add(r.tokenExpirationMinutes, 'minutes').format(),
                        developerMode: r.developerMode
                    };

                    // getting user rights for basic security model based on routes
                    saveToLocalStorage('ur', r.adminUserRights);
                    this.userRights = this.decodeUserRights(r.adminUserRights);

                    this._developerMode = r.developerMode;
                    CredentialStorage.authInfo = ai;
                    this.loginStatus.emit({type: 'info', message: this.userName, data: r})
                },
                (e:any) => this.loginStatus.emit({type: 'error', message: 'bad credentials, login failed', data: e})
            );
    }

    /**
     * @description
     * Wipes the stored data and clears instance variables
     */
    logOut() {
        CredentialStorage.removeAuthInfo();
        this.userName = undefined;
        this.password = undefined;
        removeFromLocalStorage('ur');
        this.userSignedOut.emit({type: 'info', message: 'user signed out'})
    }

    private getLoginHeaders(): HttpHeaders {
        let result = `Basic username="${this.userName}", password="${this.password}"`;

        return new HttpHeaders({'Authorization': result});
    }

}
